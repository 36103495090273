import { computed, ComputedRef, Ref, ref } from '@vue/composition-api';
import { Administrator, FetchedAdministrator, administratorApplication } from '@/admin/administrator';
import { getRoles, GetRoleResponse } from '@/admin/roles';
import officialUsers from '@/composition/officialUser';
import subscriptionPlanOption from '@/composition/subscriptionPlanOption';

export class Administrators {
  private _isLoading = ref(false);
  private _administratorData: Ref<FetchedAdministrator[]> = ref([]); // 管理ユーザー一覧取得APIから取得したデータから削除ユーザを除いて作成順に並べ替えて保持
  private _roleData: Ref<GetRoleResponse[]> = ref([]); // ロール一覧取得APIから取得したデータを作成順に並べ替えて保持
  // 管理者一覧画面で管理者情報を表示するためのデータを保持
  private _administrators: ComputedRef<Administrator[]> = computed(() => {
    const data = this._administratorData.value.map((item) => ({
      username: item.username,
      email: item.email,
      name: item.name,
      roleId: item.roleId,
      roleName: this._roleData.value.find((role) => role.roleId === item.roleId)?.roleName || '',
      linkedOfficialUserIds: item.linkedOfficialUserIds,
      linkedOfficialUserName: officialUsers.getOfficialUserNames(item.linkedOfficialUserIds).join(', '),
      linkedSubscriptionPlanIds: item.linkedSubscriptionPlanIds,
      linkedSubscriptionPlanName: subscriptionPlanOption
        .getSubscriptionPlanNames(item.linkedSubscriptionPlanIds)
        .join(', '),
    }));
    return data;
  });
  // 管理者権限の選択肢データを保持
  private _roleOptions: ComputedRef<Option[]> = computed(() => {
    return this._roleData.value.map((role) => ({ text: role.roleName, value: role.roleId }));
  });

  get isLoading() {
    return this._isLoading.value;
  }
  get administrators() {
    return this._administrators.value;
  }
  get roleOptions() {
    return this._roleOptions.value;
  }

  constructor() {
    this.getAdministrators();
  }

  /**
   * 管理ユーザー一覧、ロール一覧を取得する。
   *
   * @remarks
   * 管理ユーザー一覧取得API、ロール一覧取得APIからデータを取得し、
   * 作成順で並べ替えを行ったデータを保持する。
   * 管理ユーザー一覧は削除されているユーザーは絞り込みで含めない。
   */
  getAdministrators = async () => {
    this._isLoading.value = true;
    Promise.all([administratorApplication.getAdministrators(), getRoles()])
      .then(([_administrators, _roles]) => {
        this._administratorData.value = _administrators
          .filter((item) => !item.isDeleted)
          .sort((a, b) => a.createDate - b.createDate);
        this._roleData.value = _roles.sort((a, b) => a.createDate - b.createDate);
      })
      .finally(() => (this._isLoading.value = false));
  };
}

export const useAdministrators = () => {
  const administrators = new Administrators();
  return administrators;
};
