



























































































































































import { computed, defineComponent, ref, Ref, watch } from '@vue/composition-api';
import { administratorApplication, Administrator, InviteProps, EditProps } from '@/admin/administrator';
import { useNotification } from '@/composition/notification';
import { useAdministrators } from '@/composition/administrator';
import { useConfirm } from '@/composition/confirm';
import officialUsers from '@/composition/officialUser';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import subscriptionPlanOption from '@/composition/subscriptionPlanOption';

export default defineComponent({
  name: 'Administrators',
  components: {
    FcRoleLoading,
    FcStaticImage,
  },
  props: {
    role: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('administrators'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const administrators = useAdministrators();

    const headers = [
      { text: '名前', value: 'name' },
      { text: 'メールアドレス', value: 'email' },
      { text: '管理権限', value: 'roleName' },
      { text: '公式ユーザー', value: 'linkedOfficialUserName' },
      { text: 'サブスクリプションプラン', value: 'linkedSubscriptionPlanName' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    const roleOptions = computed(() => administrators.roleOptions);
    const officialUserOptions = computed(() =>
      officialUsers.officialUsers.map((user) => ({ text: user.name, value: user.officialUserId, icon: user.icon }))
    );
    const subscriptionPlanOptions = computed(() => {
      return subscriptionPlanOption.subscriptionPlans;
    });

    // フィルター
    const selectedRoleId = ref(props.role);
    const displayItems = computed(() =>
      administrators.administrators.filter((item) => !selectedRoleId.value || item.roleId === selectedRoleId.value)
    );

    // 登録モーダル
    const username = ref('');
    const name = ref('');
    const email = ref('');
    const roleId = ref('');
    const linkedOfficialUserIds: Ref<string[]> = ref([]);
    const linkedSubscriptionPlanIds: Ref<string[]> = ref([]);
    const isCheckAllOfficialUserIds = ref(false);
    const isCheckAllSubscriptionPlanIds = ref(false);
    const isActive = ref(false);
    const isSaving = ref(false);

    const open = (item?: Administrator) => {
      if (item) {
        username.value = item.username;
        name.value = item.name;
        email.value = item.email;
        roleId.value = item.roleId;
        linkedOfficialUserIds.value = item.linkedOfficialUserIds || [];
        linkedSubscriptionPlanIds.value = item.linkedSubscriptionPlanIds || [];
      }
      isActive.value = true;
    };

    const close = () => {
      username.value = '';
      name.value = '';
      email.value = '';
      roleId.value = '';
      linkedOfficialUserIds.value = [];
      linkedSubscriptionPlanIds.value = [];
      isActive.value = false;
    };

    const invite = async () => {
      if (!name.value || !email.value || !roleId.value) {
        alert('未入力があります。');
        return;
      }
      const props: InviteProps = {
        email: email.value,
        name: name.value,
        roleId: roleId.value,
        linkedOfficialUserIds: linkedOfficialUserIds.value || [],
        linkedSubscriptionPlanIds: linkedSubscriptionPlanIds.value || [],
      };

      isSaving.value = true;
      try {
        await administratorApplication.inviteAdministrator(props);
        notification.notify('招待メールを送信しました');
        await administrators.getAdministrators();
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
      close();
    };

    const edit = async () => {
      if (!name.value || !roleId.value) {
        alert('未入力があります。');
        return;
      }
      const props: EditProps = {
        name: name.value,
        roleId: roleId.value,
        linkedOfficialUserIds: linkedOfficialUserIds.value || [],
        linkedSubscriptionPlanIds: linkedSubscriptionPlanIds.value || [],
      };

      isSaving.value = true;
      try {
        await administratorApplication.editAdministrator(username.value, props);
        notification.notify('保存しました');
        await administrators.getAdministrators();
        // 自分のロールを再取得
        if (username.value === myAttributes.username) myAttributes.getMyRole();
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
      close();
    };

    const remove = async (administrator: Administrator) => {
      if (!(await confirmDialog(`${administrator.name} を本当に削除しますか？`))) return;
      isSaving.value = true;
      try {
        await administratorApplication.deleteAdministrator(administrator.username);
        notification.notify('削除が完了しました');
        await administrators.getAdministrators();
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    /**
     * 全て選択が押下された時、全選択または全解除を行う。
     * 現在用意されているtypeは以下
     * ・officialUser(公式ユーザー)
     * ・subscriptionPlan(サブスクリプションプラン)
     *
     * @param type - 選択・解除を行いたいチェックボックスの種類
     */
    const toggleCheckAll = (type: string) => {
      if (type === 'officialUser') {
        linkedOfficialUserIds.value = isCheckAllOfficialUserIds.value
          ? officialUserOptions.value.map((option) => option.value)
          : [];
      }
      if (type === 'subscriptionPlan') {
        linkedSubscriptionPlanIds.value = isCheckAllSubscriptionPlanIds.value
          ? subscriptionPlanOptions.value.map((option) => option.value)
          : [];
      }
    };

    watch(
      () => linkedOfficialUserIds.value,
      () => {
        if (linkedOfficialUserIds.value.length === officialUserOptions.value.length)
          isCheckAllOfficialUserIds.value = true;
        else isCheckAllOfficialUserIds.value = false;
      }
    );

    watch(
      () => linkedSubscriptionPlanIds.value,
      () => {
        if (linkedSubscriptionPlanIds.value.length === subscriptionPlanOptions.value.length)
          isCheckAllSubscriptionPlanIds.value = true;
        else isCheckAllSubscriptionPlanIds.value = false;
      }
    );

    return {
      pageTitle: '管理者',
      myRoleSettings,
      administrators,
      headers,
      selectedRoleId,
      displayItems,
      roleOptions,
      officialUserOptions,
      name,
      email,
      roleId,
      linkedOfficialUserIds,
      username,
      isCheckAllOfficialUserIds,
      isCheckAllSubscriptionPlanIds,
      isActive,
      isSaving,
      open,
      close,
      invite,
      edit,
      remove,
      toggleCheckAll,
      subscriptionPlanOptions,
      linkedSubscriptionPlanIds,
    };
  },
});
