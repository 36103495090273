import { Ref, ref } from '@vue/composition-api';
import { getOptionsTitle } from '@/admin/util';
import { SubscriptionPlan } from '@/admin/payment';
import { getSubscriptionPlans } from '@/admin/payment';

export class SubscriptionPlanOption {
  private _fetchLoading = ref(false);
  private _subscriptionPlans: Ref<{ text: string; value: string }[]> = ref([]);

  get fetchLoading() {
    return this._fetchLoading.value;
  }
  get subscriptionPlans() {
    return this._subscriptionPlans.value;
  }
  set subscriptionPlans(_subscriptionPlans: { text: string; value: string }[]) {
    this._subscriptionPlans.value = _subscriptionPlans;
  }

  constructor() {
    this.getSubscriptionPlans();
  }

  /**
   * サブスクリプションプラン一覧オブジェクトを取得する
   *
   * @remarks
   * サブスクリプションプラン一覧取得APIから取得した
   * サブスクリプションプラン一覧をプラン名とIDのオブジェクトに変換する
   *
   * @returns サブスクリプションプラン一覧オブジェクト
   */
  getSubscriptionPlans = async () => {
    this._fetchLoading.value = true;
    try {
      this._subscriptionPlans.value = await getSubscriptionPlans().then((subscriptionPlan) => {
        return subscriptionPlan.map((subscriptionsPlan: SubscriptionPlan) => {
          return {
            text: subscriptionsPlan.subscriptionPlanName,
            value: subscriptionsPlan.subscriptionPlanId,
          };
        });
      });
    } catch (e) {
      console.error(e);
    }
    this._fetchLoading.value = false;
  };

  /**
   * サブスクリプションプラン名一覧を保持する
   *
   * @param subscriptionPlanIds - サブスクリプションプランID一覧
   * @returns サブスクリプションプラン名一覧
   */
  getSubscriptionPlanNames = (subscriptionPlanIds: string[]) => {
    return subscriptionPlanIds.map((id) => getOptionsTitle(this._subscriptionPlans.value, id));
  };
}

const subscriptionPlanOption = new SubscriptionPlanOption();
export default subscriptionPlanOption;
