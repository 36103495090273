import core from '@/admin/core';

export interface FetchedAdministrator {
  username: string;
  email: string;
  name: string;
  roleId: string;
  linkedOfficialUserIds: string[];
  linkedSubscriptionPlanIds: string[];
  createDate: number;
  isDeleted: boolean;
}

export interface Administrator {
  username: string;
  email: string;
  name: string;
  roleId: string;
  roleName: string;
  linkedOfficialUserIds: string[];
  linkedOfficialUserName: string;
  linkedSubscriptionPlanIds: string[];
  linkedSubscriptionPlanName: string;
}

export interface InviteProps {
  email: string;
  name: string;
  roleId: string;
  linkedOfficialUserIds?: string[];
  linkedSubscriptionPlanIds?: string[];
}

export interface EditProps {
  name: string;
  roleId: string;
  linkedOfficialUserIds?: string[];
  linkedSubscriptionPlanIds?: string[];
}

class AdministratorApplication {
  /**
   * 管理ユーザー一覧取得API
   *
   * @returns 管理ユーザー一覧情報
   */
  async getAdministrators(): Promise<FetchedAdministrator[]> {
    const result = await core.httpClient.get('/admin/public/administrators');
    return result.data as FetchedAdministrator[];
  }

  /**
   * 管理ユーザー削除API
   *
   * @param adminUsername - 削除する管理ユーザーID
   */
  async deleteAdministrator(adminUsername: string) {
    await core.httpClient.delete(`/admin/public/administrators?userId=${adminUsername}`);
    // await core.httpClient.delete(`/admin/public/administrators/${adminUsername}`);
  }

  /**
   * 招待API
   *
   * @param props - 招待する管理ユーザー情報
   */
  async inviteAdministrator(props: InviteProps) {
    await core.httpClient.post('/admin/public/invite', props);
  }

  /**
   * 管理ユーザー更新API
   *
   * @param adminUsername - 編集する管理ユーザーID
   * @param props - 編集する管理ユーザー情報
   */
  async editAdministrator(adminUsername: string, props: EditProps) {
    await core.httpClient.put(`/admin/public/administrators/${adminUsername}`, props);
  }
}

export const administratorApplication = new AdministratorApplication();
